/* 
    ##################################################
        CSS script applicable throughout the site
        Created by John Ray Guerra - September 11, 2020
    ##################################################
 */

 /* global themes */
 a.rf-link-plain {
     color: unset !important;
     text-decoration: unset !important;
 }

/* Size Theme */
body {
    background-color: #eee !important;
}
.no-padding {
    padding: 0 !important;
}

/* color theme */
.rf-text-brw {
    color: #7a4725 !important;
}
.rf-text-blu {
    color: #355064 !important;
}
.rf-text-gry {
    color: #c5c5c5 !important;
}

/* Button Theme */
.btn-rfbackdoor {
    padding-right: 20px !important;
    padding-left: 20px !important;
    background-color: #355064 !important;
    color: #ffffff !important;
}

.btn-rfbackdoor-round {
    padding-right: 20px !important;
    padding-left: 20px !important;
    background-color: #355064 !important;
    color: #ffffff !important;
    border-radius: 25px !important;
}

.btn-rfbackdoor-outline {
    padding-right: 20px !important;
    padding-left: 20px !important;
    background-color: none !important;
    color:  #355064 !important;
    border-color: #355064 !important;
    border-width: 2px !important;
}

.btn-rfbackdoor-round-outline {
    padding-right: 20px !important;
    padding-left: 20px !important;
    background-color: none !important;
    color:  #355064 !important;
    border-color: #355064 !important;
    border-width: 2px !important;
    border-radius: 25px !important;
}

.button-end {
    text-align: end !important;
}

.round {
    border-radius: 15px !important;
}

.rfbackdoor-badge-options {
    margin: 10px;
    padding: 10px;
    border: 1px #17a2b8 solid;
    border-radius: 15px;
    white-space: nowrap;
}

.rfbackdoor-badge-options-active {
    color: #fff;
    background-color: #17a2b8;
}


/* background */
.rfbackdoor-dark-bg {
    background-color: #343a40;
}



/* box styles */
.box {
    /* box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12) !important; */
    background: #fff !important;
}
.icon-box {
    background-color: #eee !important;
}

.rfbackdoor-scrollable-y {
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
}


/* cursor */

.pointer:hover {
    cursor: pointer !important;
}


/* navigation links */
.rfbackdoor-brand {
    max-width: 250px;
    min-width: 200px;
}

.rfbackdoor-navlink {
    color: #fff;
    /* text-transform: uppercase; */
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    margin: 0 15px;
    padding: 15px 20px;
    position: relative;
}

.rfbackdoor-navlink:after{
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: #2196f3;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.rfbackdoor-navlink:hover:after { 
    width: 100%; 
    left: 0; 
}

/* Calculator Images */

.rfbackdoor-tools-image {
    display: flex;
    align-self: start;
    width: 350px;
    height: 250px;
}

.rfbackdoor-getting-started-image {
    width: 100%;
}

.filter-menu {
    /* z-index: 100; */
    height: 100%;
    /* width: 500px; */
    /* position: fixed; */
    z-index: 4;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-y: scroll;
    transition: 0.3s;
    padding-top: 60px;
    -moz-box-shadow:    -3px 0 5px 0 #555;
    -webkit-box-shadow: -3px 0 5px 0 #555;
    box-shadow:         -3px 0 5px 0 #555;
    
}

.filter-hide {
    width: 0;
}

.filter-show {
    width: 350px;
}

.filter-menu .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.ellipsis {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 18px; /* fallback */
    max-height: 54px; /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}


.scroll-to-top-button {
    display: flex;
    visibility: visible;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #7a4725;
    border-radius: 50px;
    opacity: 1;
    transition: visibility 1s, opacity 1s ease-in
}

.scroll-to-top-button:hover {
    background-color: #355064;
    transition: background-color 0.5s ease-in;
}

.scroll-to-top-button-hide {
    opacity: 0;
    visibility: hidden;
}

.footer-fixed {
    position: fixed;
    bottom: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .rfbackdoor-tools-image {
        display: flex;
        align-self: center;
        width: 80% !important;
        height: auto !important;
    }
    .rfbackdoor-mobile-hide {
        display: none !important;
        visibility: hidden !important;
    }
    .rfbackdoor-getting-started-image {
        width: 80%;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .rfbackdoor-tools-image {
        display: flex;
        align-self: center;
        width: 80% !important;
        height: auto !important;
    }
    .rfbackdoor-mobile-hide {
        display: none !important;
        visibility: hidden !important;
    }
    .rfbackdoor-desktop-hide {
        display: block;
        visibility: visible;
    }
    .rfbackdoor-getting-started-image {
        width: 80%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .rfbackdoor-tools-image {
        display: flex;
        align-self: center;
        width: 80% !important;
        height: auto !important;
    }
    .rfbackdoor-desktop-hide {
        display: none !important;
        visibility: hidden;
    }
    .rfbackdoor-getting-started-image {
        width: 80%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .rfbackdoor-tools-image {
        display: flex;
        align-self: center;
        width: 80% !important;
        height: auto !important;
    }
    .rfbackdoor-desktop-hide {
        display: none !important;
        visibility: hidden;
    }
    .rfbackdoor-getting-started-image {
        width: 80%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .rfbackdoor-desktop-hide {
        display: none !important;
        visibility: hidden;
    }
}